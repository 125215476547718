import { Category } from "./category";
import { Service } from "./ticketServices";
import { WorkPlace } from "./workPlace";
import { Description } from "./description";
import { CompanyPlaceUser } from "./companyPlaceUser";
import { Company } from "./company";
import { Client } from "./client";
import { Location } from "./location";
import { PhoneList } from "./phoneList";
import { Priority } from "./priority";
import { Upload } from "./upload";
import { BillsInfo } from "./billsInfo";
import { Bills } from "./bills";
import { Assignments } from "./assignments";
import { Costs } from "./costs";
import { Payment } from "./payment";
import BillsHistory from "./billsHistory";

const TicketInput = {
  Category,
  Service,
  WorkPlace,
  Description,
  CompanyPlaceUser,
  Company,
  Client,
  Location,
  PhoneList,
  Priority,
  Upload,
  BillsInfo,
  Bills,
  Assignments,
  Costs,
  Payment,
  BillsHistory,
};

export default TicketInput;
