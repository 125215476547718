import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import { FormInstance } from "antd/lib/form";
import { t } from "i18next";

export const Description = ({
  name,
  required = false,
  disabled = false,
  rules,
  readOnly = false,
  form,
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  rules: Rule[];
  readOnly?: boolean;
  form: FormInstance;
}) => {
  return (
    <Form.Item
      label={<>{t("description")}</>}
      name={name}
      rules={rules}
      required={required}
    >
      <Input.TextArea
        readOnly={readOnly}
        disabled={disabled}
        dir="auto"
        autoComplete="off"
      />
    </Form.Item>
  );
};
