import { PercentageOutlined } from "@ant-design/icons";
import { Form, Input, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { filters } from "components/filters/ticketsFillters";
import PermissionsGate from "components/permissionsGate";
import { t } from "i18next";
import { UserModel } from "models/users";
import { useEffect, useState } from "react";
import { isClient } from "utils/helpers/check-roles";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Roles, Ticket } from "utils/helpers/static_data";

export const Payment = ({
  Status,
  form,
  remainingAmount,
}: {
  Status: filters["Status"];
  form: FormInstance;
  remainingAmount: number;
}) => {
  const [totalPayment, setTotalPayment] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [precent, setPrecent] = useState(0);

  useEffect(() => {
    if (remainingAmount) {
      setTotalPayment(remainingAmount!);
    }
  }, [remainingAmount]);

  const PercentToNumber = () => {
    const x = parseFloat(form.getFieldValue("payment"));
    const y = (x * parseFloat(form.getFieldValue("percent"))) / 100.0;

    if (Number.isNaN(y)) form.setFieldValue("discount", "0");
    else {
      setDiscount(y);
      form.setFieldValue("discount", y);
    }
    form.validateFields(["discount", "percent"]);
  };
  const NumberToPercent = () => {
    const x = parseFloat(form.getFieldValue("payment"));
    const y = (
      (100.0 * parseFloat(form.getFieldValue("discount"))) /
      x
    ).toFixed(0);
    if (Number.isNaN(y)) form.setFieldValue("percent", "0");
    else form.setFieldValue("percent", y);
    form.validateFields(["discount", "percent"]);
  };

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);

  const validatedAmount =
    remainingAmount !== 0 ? remainingAmount : totalPayment - discount;

  return (
    <>
      <PermissionsGate scopes={["read_ticket_payment"]}>
        <h1 className="formHeader paid">{t("work_cost")}</h1>
        <Form.Item
          name={"payment"}
          label={t("ticket_final_cost")}
          rules={[
            {
              required: Status === Ticket.approved,
              message: t("please_input_final_cost"),
            },
            {
              validator: (_, value) => {
                if (value && parseInt(value) < 1)
                  return Promise.reject(new Error(t("greater_than_0")));
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            type="number"
            onChange={(e) => {
              setTotalPayment(+e.target.value);
              PercentToNumber();
            }}
            disabled={Status !== Ticket.approved || isClient(User?.role_id!)}
          />
        </Form.Item>
        <Space.Compact className="groupInputContainer">
          <Form.Item
            style={{ width: "60%" }}
            name={"discount"}
            label={t("discount_value")}
            dependencies={["payment", "percent"]}
            rules={[
              {
                required: Status === Ticket.approved,
                message: t("please_input") + " " + t("discount_value"),
              },
              ({ getFieldValue, setFieldValue }) => ({
                validator(_, value) {
                  if (value && parseInt(value) < 0)
                    return Promise.reject(
                      new Error(t("greater_than_or_equal_0"))
                    );
                  if (
                    value &&
                    parseInt(value) > parseInt(getFieldValue("payment"))
                  )
                    return Promise.reject(
                      new Error(t("should_not_be_greater_than_final_cost"))
                    );
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="groupInput-1"
              defaultValue={"0"}
              type="number"
              onChange={(e) => {
                setDiscount(+e.target.value);
                NumberToPercent();
              }}
              disabled={Status !== Ticket.approved || isClient(User?.role_id!)}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "40%" }}
            name={"percent"}
            label={<></>}
            dependencies={["payment", "discount"]}
            rules={[
              {
                validator(_, value) {
                  if (
                    value &&
                    (parseFloat(value) < 0.0 || parseFloat(value) > 100.0)
                  )
                    return Promise.reject(new Error(t("between_0_and_100")));
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              className="groupInput-2"
              defaultValue={"0"}
              type="number"
              min={0}
              max={100}
              onChange={(e) => {
                setPrecent(+e.target.value);
                PercentToNumber();
              }}
              disabled={Status !== Ticket.approved || isClient(User?.role_id!)}
              prefix={<PercentageOutlined style={{ fontSize: 18 }} />}
            />
          </Form.Item>
        </Space.Compact>
        {(Status === Ticket.partialPaid || Status === Ticket.approved) &&
          !isClient(User?.role_id!) && (
            <Form.Item
              name={"collected_payments"}
              label={t("payment")}
              dependencies={["precent", "discount"]}
              rules={[
                {
                  required:
                    Status === Ticket.approved || Status === Ticket.partialPaid,
                  message: t("please_input") + " " + t("payment"),
                },
                {
                  validator(_, value) {
                    if (value && parseFloat(value) > validatedAmount) {
                      return Promise.reject(
                        new Error(
                          `${t(
                            "should_not_be_greater_than_remaining_cost"
                          )} ${validatedAmount}`
                        )
                      );
                    } else if (value && parseFloat(value) < 0.0) {
                      return Promise.reject(new Error(t("greater_than_0")));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                min={0}
                max={validatedAmount}
                disabled={
                  Status !== Ticket.approved && Status !== Ticket.partialPaid
                }
              />
            </Form.Item>
          )}
        {User?.role_id === Roles.client && (
          <>
            <Form.Item name={"client_payment"} label={t("paid_value")}>
              <Input readOnly disabled />
            </Form.Item>
          </>
        )}
      </PermissionsGate>
    </>
  );
};
