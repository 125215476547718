import { Col, Radio } from "antd";
import TicketInput from "../forms/inputs";
import { hasPermission } from "components/permissionsGate";
import { Roles, Ticket } from "utils/helpers/static_data";
import { useEffect, useState } from "react";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { TicketModel } from "models/tickets";
import { FormInstance } from "antd/lib/form";
import { t } from "i18next";

interface RequestedByInput {
  ticket?: TicketModel;
  Status: any;
  form: FormInstance<any>;
  clientRequest?: any;
  setUserContact: any;
  setCompanyPlace: any;
  CompanyInputs: any;
  setCompanyInputs: any;
  company: any;
  setCompany: any;
}

const RequestedByInput = ({
  ticket,
  Status,
  form,
  clientRequest,
  setUserContact,
  setCompanyPlace,
  CompanyInputs,
  setCompanyInputs,
  company,
  setCompany,
}: RequestedByInput) => {
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);
  return (
    <div style={{ position: "relative" }}>
      <TicketInput.Company
        name={["company_place", "requested_by"]}
        form={form}
        ticket={ticket}
        enabled={CompanyInputs === "company" && User?.role_id !== Roles.client}
        setCompanyPlace={setCompanyPlace}
        setUserContact={setUserContact}
        disabled={
          !hasPermission([
            Status === Ticket.create ? "add_tickets" : "edit_tickets",
          ])
        }
        clientRequest={clientRequest}
        rules={[
          {
            required: CompanyInputs === "company",
            message: t("please_select_company_first"),
          },
        ]}
        company={company}
        setCompany={setCompany}
      />
    </div>
  );
};

export default RequestedByInput;
