import { Col, Form, FormInstance, Input, Radio, Row } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import React, { useEffect, SetStateAction } from "react";
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

type Props = {
  form: FormInstance<any>;
  visitsCount: number;
  setVisitsCount: React.Dispatch<SetStateAction<number>>;
  setSelectedDayDate: React.Dispatch<SetStateAction<string>>;
};

function TicketsDateInput({
  form,
  visitsCount,
  setVisitsCount,
  setSelectedDayDate,
}: Props) {
  const countSelectedDayInRange = (
    startDate: any,
    endDate: any,
    selectedDay: string
  ) => {
    let count = 0;

    while (startDate.isSameOrBefore(endDate)) {
      if (startDate.day() + 1 == selectedDay) {
        count++;
      }
      startDate = startDate.add(1, "day");
    }

    return count;
  };

  const handleDateChange = (event?: any) => {
    const selectedDayDate = parseInt(
      event ? event.target.value : form.getFieldValue("visit_day"),
      10
    );
    const currentDate = dayjs();
    const currentDay = currentDate.day();
    const daysToAdd =
      selectedDayDate > currentDay
        ? selectedDayDate - currentDay - 1
        : 7 - (currentDay - selectedDayDate) - 1;
    const selectedDate = currentDate.add(daysToAdd, "day");

    console.log("Selected date:", selectedDate.format("YYYY-MM-DD"));
    setSelectedDayDate(selectedDate.format("YYYY-MM-DD"));

    const dateIn = dayjs(form.getFieldValue("contract_start_date"));
    const dateOut = dayjs(form.getFieldValue("contract_end_date"));
    const selectedDay = form.getFieldValue("visit_day");

    const count = countSelectedDayInRange(dateIn, dateOut, selectedDay);
    setVisitsCount(count);
  };

  useEffect(() => {
    handleDateChange();
  }, [
    form.getFieldValue("contract_start_date"),
    form.getFieldValue("contract_end_date"),
    form.getFieldValue("visit_day"),
  ]);

  return (
    <div style={{ marginBottom: "1rem" }}>
      <Form.Item
        label={<>{t("visit_day")}</>}
        name="visit_day"
        initialValue={1}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group
          defaultValue={0}
          onChange={handleDateChange}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Radio value={1}>{t("sunday")}</Radio>
          <Radio value={2}>{t("monday")}</Radio>
          <Radio value={3}>{t("tuesday")}</Radio>
          <Radio value={4}>{t("wednesday")}</Radio>
          <Radio value={5}>{t("thursday")}</Radio>
          <Radio value={6}>{t("friday")}</Radio>
          <Radio value={7}>{t("saturday")}</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={<>{t("time")}</>}
        name={"visit_time"}
        initialValue={"14:00"}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type={"time"} autoComplete="off" />
      </Form.Item>
      <Row justify="center" gutter={[20, 0]} style={{ marginTop: "1rem" }}>
        <Col span={24} lg={12}>
          <Form.Item
            label={<>{t("start")}</>}
            name={"contract_start_date"}
            initialValue={dayjs().format("YYYY-MM-DD")}
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    dayjs(value).isAfter(
                      dayjs(form.getFieldValue("contract_end_date"))
                    )
                  ) {
                    return Promise.reject(t("start_date_before_end_date"));
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value && dayjs(value).isBefore(dayjs(), "day")) {
                    return Promise.reject(t("start_date_not_in_the_past"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type={"date"}
              autoComplete="off"
              onChange={handleDateChange}
            />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item
            label={<>{t("finish")}</>}
            name={"contract_end_date"}
            initialValue={dayjs().add(1, "year").format("YYYY-MM-DD")}
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    dayjs(value).isBefore(
                      dayjs(form.getFieldValue("contract_start_date"))
                    )
                  ) {
                    return Promise.reject(t("end_date_after_start_date"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type={"date"}
              autoComplete="off"
              onChange={handleDateChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <p style={{ textAlign: "center" }}>
        {t("number_of_visits")} : {visitsCount}
      </p>
    </div>
  );
}

export default TicketsDateInput;
