import axios from "axios";
import { useMutation } from "react-query";
import { useAppDispatch } from "store";
import { isError } from "utils/api/models/api-response";
import { checkAuth } from "state";
import {
  LOGIN_DISABLED,
  LOGIN_FAIL,
  LOGIN_NOT_VERIFY,
  LOGIN_SUCCESS,
} from "actions/types";
import { Button, notification } from "antd";
import { UserModel } from "models/users";
import { Token, UserData } from "utils/helpers/encrypt-decrypt-user";
import ApiResult from "utils/api/models/api-result";
import { useNavigate } from "react-router-dom";
import { isClient } from "utils/helpers/check-roles";

const login = async ({
  user_name,
  password,
}: {
  user_name: string;
  password: string;
}) => {
  const URL = `${process.env.REACT_APP_API_URL}api/login`;
  return await axios
    .post<ApiResult<UserModel> & { token?: string }>(
      URL,
      {
        user_name,
        password,
      },
      { headers: { "Accept-Language": "ar" } }
    )
    .then((response) => {
      // if (!response.data.data.is_phone_number_verify) {
      //   throw Error(LOGIN_NOT_VERIFY);
      // }
      if (response.data.token) {
        Token.Set(response.data.token);
        UserData.Encrypt(response.data.data);
      }
      // getTokenAsync();
      return response.data;
    })
    .catch((error) => {
      if (error?.response?.status === 403) {
        notification.open({
          type: "warning",
          message: "Warning",
          description: "Your account is disabled!",
        });
        throw Error(LOGIN_DISABLED);
      } else if (error?.response?.status === 401) {
        notification.open({
          type: "error",
          message: "Error",
          description: error.response.data.message,
        });
        throw Error(LOGIN_FAIL);
      } else if (error?.response?.status === 422) {
        const keys = Object.keys(error?.response?.data?.errors);
        keys.forEach((key) => {
          notification.open({
            type: "error",
            message: "Error",
            description: error?.response?.data?.errors[key],
          });
        });
        throw error;
      } else {
        notification.open({
          type: "error",
          message: error.message,
        });
        throw error;
      }
    });
};

export default function useLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return {
    ...useMutation(login, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          dispatch(
            checkAuth({
              type: LOGIN_SUCCESS,
              payload: { user: res.data },
            })
          );
          navigate({
            pathname: !isClient(res.data.role_id!)
              ? "/client-requests"
              : "/c-tickets",
          });
        }
      },
      onError: async (err: Error) => {
        dispatch(checkAuth({ type: err.message }));
      },
    }),
  };
}
