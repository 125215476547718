import React, { ReactNode, useEffect, useState } from "react";

import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import CPLevels from "pages/controlPanel/levels";
import CPServices from "pages/controlPanel/services";
import CPCategories from "pages/controlPanel/categories";
import { Roles } from "utils/helpers/static_data";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import ProtectedRoute from "./ProtectedRoute.routes";
import useCurrentUser from "hooks/query/users/useCurrentUser";
import { isClient } from "utils/helpers/check-roles";
import ClientCategories from "pages/controlPanel/client-categories";
import FinalReports from "pages/final-reports";

const Home = React.lazy(() => import("pages/home"));

const ClientRequests = React.lazy(() => import("pages/clientRequests"));

const Tickets = React.lazy(() => import("pages/tickets"));
const Ticket = React.lazy(() => import("pages/tickets/ticket/TicketDetails"));

const Technicians = React.lazy(() => import("pages/technicians"));
const Technical = React.lazy(() => import("pages/technicians/technical"));

const Profile = React.lazy(() => import("pages/profile"));

const Companies = React.lazy(() => import("pages/companies"));

const Users = React.lazy(() => import("pages/users"));
const User = React.lazy(() => import("pages/users/user"));

const Clients = React.lazy(() => import("pages/clients"));
const Client = React.lazy(() => import("pages/clients/client"));

const ControlPanel = React.lazy(() => import("pages/controlPanel"));
const CommissionReports = React.lazy(
  () => import("pages/reports/techniciansCommissions")
);

const SystemSettings = React.lazy(() => import("pages/systemSettings"));

const ClientTickets = React.lazy(() => import("pages/client-tickets"));

const Customers = React.lazy(() => import("pages/customers"));

const Dashboard = React.lazy(() => import("pages/dashboard"));

interface RouteInterface {
  path: string;
  component: ReactNode;
  name: string;
  permissions?: string[];
  children?: ReactNode[];
  roles?: any;
  allowedIds?: number[];
}

const routes: Array<RouteInterface> = [
  {
    path: "/",
    component: <Home />,
    name: "Home",
    permissions: ["browse_tickets"],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
    permissions: ["browse_dashboard"],
    roles: [Roles.dispatcher],
    allowedIds: [291, 513],
  },
  {
    path: "/final-reports",
    name: "FinalReports",
    component: <FinalReports />,
    permissions: ["browse_final_reports"],
  },
  {
    path: "/client-requests",
    component: <ClientRequests />,
    name: "ClientRequests",
    permissions: ["browse_tickets"],
    roles: [
      Roles.accountant,
      Roles.admin,
      Roles.dispatcher,
      Roles.distributor,
      Roles.operation,
      Roles.sales,
      Roles.technical,
      Roles.coordinator,
    ],
  },
  {
    path: "/client-requests/:client_request_id",
    component: <Tickets />,
    name: "Tickets",
    permissions: ["browse_tickets"],
    roles: [
      Roles.accountant,
      Roles.admin,
      Roles.dispatcher,
      Roles.distributor,
      Roles.operation,
      Roles.sales,
      Roles.technical,
      Roles.coordinator,
    ],
  },

  {
    path: "/tickets/:id",
    component: <Ticket />,
    name: "Ticket",
    permissions: ["read_tickets"],
    roles: [
      Roles.accountant,
      Roles.admin,
      Roles.dispatcher,
      Roles.distributor,
      Roles.operation,
      Roles.sales,
      Roles.technical,
      Roles.coordinator,
    ],
  },

  {
    path: "/technicians",
    component: <Technicians />,
    name: "Technicians",
    permissions: ["browse_technical_profiles"],
  },
  {
    path: "/technicians/:id",
    component: <Technical />,
    name: "Technical",
    permissions: ["read_technical_profiles"],
  },
  {
    path: "/profile",
    component: <Profile />,
    name: "Profile",
  },
  {
    path: "/users",
    component: <Users />,
    name: "Users",
    permissions: ["browse_users"],
  },
  {
    path: "/users/:id",
    component: <User />,
    name: "User",
    permissions: ["read_users"],
  },
  {
    path: "/reports",
    component: <CommissionReports />,
    name: "Reports",
    permissions: ["read_ticket_percentage"],
  },
  {
    path: "/system-settings",
    component: <SystemSettings />,
    name: "System Settings",
    permissions: ["browse_settings", "read_settings"],
  },
  {
    path: "/control-panel",
    component: <ControlPanel />,
    name: "control panel",
    permissions: ["browse_categories", "read_categories"],
    children: [
      { path: "levels", component: <CPLevels />, name: "cp-levles" },
      { path: "services", component: <CPServices />, name: "cp-services" },
      {
        path: "categories",
        component: <CPCategories />,
        name: "cp-categories",
      },
      {
        path: "clients-categories",
        component: <ClientCategories />,
        name: "cp-clients-categories",
      },
    ],
    roles: [Roles.dispatcher, Roles.operation, Roles.sales, Roles.coordinator],
  },
  {
    path: "/c-tickets",
    component: <ClientTickets />,
    name: "client tickets",
    permissions: ["browse_tickets"],
    roles: [Roles.client],
  },
  {
    path: "/c-tickets/:id",
    component: <Ticket />,
    name: "client ticket details",
    permissions: ["browse_tickets"],
    roles: [Roles.client],
  },
  {
    path: "/customers",
    component: <Customers />,
    name: "Customers",
    permissions: ["browse_companies", "browse_users"],
  },
  {
    path: "/customers/clients/:id",
    component: <Client />,
    name: "Client",
    permissions: ["browse_companies", "browse_users"],
  },
  {
    path: "*",
    component: (
      <Navigate
        to={
          isClient(UserData.Decrypt()?.role_id!)
            ? "/c-tickets"
            : "/client-requests"
        }
      />
    ),
    name: "default",
    permissions: ["browse_tickets"],
  },
];

let isInit = true;

const PagesRoutes = () => {
  const location = useLocation();
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);
  const { refetch } = useCurrentUser({ enabled: false });

  useEffect(() => {
    if (isInit) {
      refetch().then((response) => {
        if (response.data?.data) {
          UserData.Encrypt(response.data.data);
        }
      });
    }
  }, []);
  return (
    <Routes location={location}>
      {routes.map((route) => {
        if (route.children) {
          return (
            <Route
              path={route.path}
              element={
                <ProtectedRoute
                  roles={route.roles}
                  permissions={route.permissions}
                >
                  {route.component}
                </ProtectedRoute>
              }
              key={route.name}
            >
              {route.children.map((route: any) => (
                <Route
                  path={route.path}
                  element={route.component}
                  key={route.name}
                />
              ))}
            </Route>
          );
        }
        return (
          <Route
            key={route.name}
            path={route.path}
            element={
              <ProtectedRoute
                roles={route.roles}
                permissions={route.permissions}
                allowedIds={route.allowedIds}
              >
                {route.component}
              </ProtectedRoute>
            }
          />
        );
      })}
      <Route
        path="/control-panel/*"
        element={<Navigate to="/control-panel/levels" />}
      />
    </Routes>
  );
};

export default PagesRoutes;
